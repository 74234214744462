import typography from "./typography";
import colors from "./colors";

const {
  info,
  dark,
  text,
} = colors;

const { size } = typography;

export default {
  ':root': {
    colorScheme: 'only light',
  },
  html: {
    width: '100%',
    minWidth: '360px',
    scrollBehavior: "smooth",
  },
  body: {
    fontSize: size.regular,
    lineHeight: 'inherit',
    overflowX: 'hidden',
    color: text.main,
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important',
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important',
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`,
  },
  'section': {
    position: "relative",
  },
  '.swiper': {
    paddingBottom: 26,
  },
  '.swiper-button-next, .swiper-button-prev': {
    height: '30px !important',
    width: '30px !important',
    borderRadius: '50rem !important',
    backgroundColor: '#000 !important',
  },
  '.swiper-button-next': {
    right: '50% !important',
    transform: 'translateX(120%) !important',
  },
  '.swiper-button-prev': {
    left: '50% !important',
    transform: 'translateX(-120%) !important',
  },
  '.w-auto': {
    width: 'auto !important',
  },
  ".img-fluid": {
    maxWidth: "100%",
    height: "auto",
  },
  "[data-disabled='true']": {
    cursor: "not-allowed",
    pointerEvents: "none",
    "& button": {
      cursor: "not-allowed",
      pointerEvents: "none",
    }
  },
  ".fis": {
    fontSize: 44,
    borderRadius: "50%",
  },
  "input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      appearance: "none",
      WebkitAppearance: "none",
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: "textfield",
    }
  },
  ".MuiPickersCalendarHeader-labelContainer, .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton": {
    fontSize: "1rem"
  },
  ".MuiPickersDay-root,.MuiPickersYear-yearButton": {
    "&.Mui-selected": {
      color: "#fff !important",
    }
  },
};
