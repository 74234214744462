import { useUserLogoutMutation } from "app/features/apis/AuthApi";
import { setCredentials } from "app/features/authSlice";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export const logoutFn = (navigate, dispatch) => {
  const payload = {
    token: null,
    isAuthenticated: false,
    loggedInTime: null,
    rememberMe: null,
  };
  localStorage.removeItem('auth_token');
  localStorage.removeItem('loggedInTime');
  localStorage.removeItem('rememberMe');
  navigate('/', { replace: true })
  dispatch(setCredentials(payload));
}

const useLogout = () => {
  const { isOffline } = useSelector(state => state.uiCtrl);
  const [handleLogout] = useUserLogoutMutation();
  const dispatch = useDispatch();
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const logout = async () => {
    try {
      const response = await handleLogout().unwrap();
      if (response.code) {
        logoutFn(navigate, dispatch);
        suiSnackbar(response.message, "success");
      }
    } catch (error) {
      if (error) {
        if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
          suiSnackbar("Internal server error!");
        } else if (error?.originalStatus === 503) {
          suiSnackbar("Service Unavailable!");
        } else if (error?.data) {
          if ([403, 500].includes(error?.data.statusCode)) {
            logoutFn(navigate, dispatch);
          }
          suiSnackbar(error?.data?.message);
        } else {
          suiSnackbar(isOffline ? "Check your Internet connection and try again!" : "Something went wrong!");
        }
      } else {
        suiSnackbar('Network error or unexpected issue');
      }
    }
  }
  
  return logout;
}

export default useLogout;