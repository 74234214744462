import Snackbar from '@mui/material/Snackbar';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setIsOffline } from 'app/features/uiCtrlSlice';


const Alert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}));

const InternetNotAvailableSnackbar = () => {
  const { isOffline } = useSelector(state => state.uiCtrl);
  const dispatch = useDispatch();
  useEffect(() => {
    const checkClient = setInterval(() => {
      dispatch(setIsOffline(!window.navigator.onLine));
    }, 5000);
    return () => clearInterval(checkClient);
  }, [dispatch]);
  
  return (
    <Snackbar open={isOffline} autoHideDuration={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert
        icon={<WarningRoundedIcon fontSize="inherit" />}
        severity="error"
        variant="filled"
        sx={{ minWidth: 200 }}
      >
        Internet is not available!
      </Alert>
    </Snackbar>
  );
};

export default InternetNotAvailableSnackbar;
