
import { Box, IconButton, styled } from "@mui/material";

export const FooterStyled = styled(({ children, ...rest }) => {
  return (<Box {...rest}>{children}</Box>);
})(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    backgroundColor: "#0A1A38",
    paddingTop: 60,
    [breakpoints.down("md")]: {
      paddingTop: 40,
    },
    "& .footer-join": {
      paddingBottom: 60,
      textAlign: "center",
      [breakpoints.down("md")]: {
        paddingBottom: 40,
      },
    },
    "& .footer-top": {
      padding: "40px 0",
      borderTop: "1px solid #1D3563",
      [breakpoints.down("lg")]: {
        padding: "40px 0 20px",
      },
      "& .nav-item": {
        marginBottom: 10,
        "&> .nav-link": {
          color: palette.text.light,
          "&:hover": {
            color: palette.primary.main,
          },
        },
        "&:nth-last-of-type(1)": {
          marginBottom: 0,
        },
      },
    },
    "& .footer-bottom": {
      padding: "20px 0",
      borderTop: "1px solid #1D3563",
    },
  };
})

export const SocialIconButton = styled(IconButton)(({ theme }) => {
  return {
    transition: "all 0.5s ease-in-out 0s",
    backgroundColor: "rgba(168, 168, 168, 0.2)",
    flexShrink: 0,
    width: 32,
    height: 32,
    "&> svg": {
      fill: theme.palette.light.main,
    },
    "&:hover, &:active": {
      backgroundColor: "rgba(168, 168, 168, 0.2)",
    },
    "&.facebook": {
      "&:hover, &:active": {
        backgroundColor: "#3080F8",
      },
    },
    "&.twitter": {
      "&:hover, &:active": {
        backgroundColor: "#000",
      },
    },
    "&.instagram": {
      "&:hover, &:active": {
        backgroundColor: "#FF2D20",
      },
    },
    "&.linkedin": {
      "&:hover, &:active": {
        backgroundColor: "#0077b5",
      },
    },
    "&.youtube": {
      "&:hover, &:active": {
        backgroundColor: "#CD201F",
      },
    },
  };
})