import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "routes";
import { v4 as uuidv4 } from "uuid";
// import { getToken } from "firebase/messaging";
// import { messaging } from "./firebase/firebaseConfig";
// import '../firebase-messaging-sw'

// async function requestDevice() {

//   try {
//     const token = await getToken(messaging, {
//       vapidKey:
//         "AAAAHk2T0iI:APA91bEHY-4rpB9dEtwmfM6HqrKG7GeQ9YAgnX0p0Ch3x90fkg4D3Pf1jnTntj20Kb9CLF6z0HU7iPl9vjwsu4ieFMho2km2LlTzEKoWS2hOFXF-Td1flbxUVhqTUmkEmYIs25DnKtO4",
//     });
//     console.log("Token generated : ", token);
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }
// }

const App = () => {
  const newDeviceId = uuidv4();
  if (!localStorage.getItem("deviceId")) {
    localStorage.setItem("deviceId", newDeviceId);
  }

  // useEffect(() => {
  //   requestDevice()
  // }, [])

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};

export default App;
