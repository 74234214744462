export default {
  styleOverrides: {
    root: {
      fontSize: "0.85rem",
      fontWeight: 500,
      marginRight: 4,
      marginLeft: 4,
    },
  },
};
