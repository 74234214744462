/* eslint-disable react-refresh/only-export-components */
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Logout from "@mui/icons-material/Logout";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

import { cloneElement, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.png";
import logoYellow from "assets/images/logo-yellow.png";
import { useDispatch, useSelector } from "react-redux";
import { mainNavMobileLinks, planLevelIcons } from "common/websiteData";
import { LoginButton, NavDrawer, ScrollTopArrowUI } from "./style";
import useActivePath from "hooks/useActivePath";
import useGetScroller from "hooks/useGetScroller";
import { stringAvatar } from "helper";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import useLogout from "pages/authentication/logout/useLogout";
import { useUserProfileQuery } from "app/features/apis/UserApi";
import { setCredentials } from "app/features/authSlice";
import styled from "@emotion/styled";

export const ElevationScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  const headerClass = useActivePath("/") ? "header-dark" : "header-light";
  return cloneElement(children, {
    elevation: trigger ? 10 : 0,
    className: `${headerClass} ${trigger ? "scrolled" : ""}`,
  });
};

export const Logo = () => {
  return (
    <img
      className="img-fluid nav-brand"
      width="130"
      height="84"
      src={useActivePath("/") ? logo : logoYellow}
      alt="Royal Badminton"
      title="Royal Badminton"
    />
  );
};

export const NavbarLink = ({ href, label, ...rest }) => {
  return (
    <Typography
      component={NavLink}
      fontSize={16}
      fontWeight={500}
      className={"nav-link"}
      to={href}
      {...rest}
    >
      {label}
    </Typography>
  );
};

export const MobileNavToggler = () => {
  const { pathname } = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const { isAuthenticated } = useSelector((store) => store.auth);
  const loginmenu = mainNavMobileLinks.slice(0, mainNavMobileLinks.length - 2);
  const menu = isAuthenticated ? loginmenu : mainNavMobileLinks;
  useEffect(() => {
    setOpenNav(false);
    return () => {
      setOpenNav(false);
    };
  }, [pathname]);
  return (
    <>
      <Box className="bar-icon" onClick={() => setOpenNav(true)}>
        <span />
        <span />
        <span />
      </Box>
      <NavDrawer
        anchor="left"
        open={openNav}
        PaperProps={{
          className: "mobileNavDrawer",
        }}
        onClose={() => setOpenNav(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          pr={2}
          justifyContent="space-between"
        >
          <Box component={Link} to="/">
            <img
              className="img-fluid nav-brand"
              width="120"
              height="78"
              src={logoYellow}
              alt="Royal Badminton"
              title="Royal Badminton"
            />
          </Box>
          <IconButton color="primary" onClick={() => setOpenNav(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Box maxWidth={260} minWidth={260} className="nav-wrap" height="100%">
          <Grid
            container
            component="nav"
            className="mobile-nav"
            alignContent="start"
            rowGap={0.5}
          >
            {menu?.map(({ id, href, label }) => (
              <Grid item xs={12} key={id}>
                <NavbarLink href={href} label={label} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </NavDrawer>
    </>
  );
};

export const NavBottomSpace = () => {
  return (
    <Toolbar
      sx={{
        "&.MuiToolbar-root": {
          display: useActivePath("/") ? "none" : "flex",
          minHeight: 88,
          bgcolor: "transparent",
        },
      }}
    />
  );
};

export const ScrollTop = (props) => {
  const { window } = props;
  const [getScroller] = useGetScroller();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const html = document.documentElement;
    html.style.scrollBehavior = "smooth";

    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
    html.style.scrollBehavior = "unset";
  };

  return (
    <Slide direction="up" in={trigger} mountOnEnter unmountOnExit>
      <ScrollTopArrowUI onClick={handleClick} role="presentation">
        <Box
          component="svg"
          className="progress-circle"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <Box
            component="path"
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            sx={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: `${307.919 - 3.07919 * getScroller}`,
            }}
          />
        </Box>
        <ArrowUpwardRoundedIcon color="primary" className="up-arrow" />
      </ScrollTopArrowUI>
    </Slide>
  );
};

export const MemberAvatar = styled(Avatar)(() => ({
  borderWidth: 2,
  borderStyle: "solid",
  "&.level-1": {
    borderColor: "#d38f1d",
  },
  "&.level-2": {
    borderColor: "#6d95d3",
  },
  "&.level-3": {
    borderColor: "#ffcd00",
  },
}));

export const SmallAvatar = styled(Avatar)(() => ({
  width: 19,
  height: 19,
  borderRadius: 0,
  "&> .MuiAvatar-img": {
    objectFit: "contain",
  },
  "&.profile-plan": {
    width: 45,
    height: 45,
  },
}));

const ProfileBox = ({ data }) => {
  const logout = useLogout();

  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (anchorEl === null) {
      setAnchorEl("");
    }
  }, [pathname, setAnchorEl, anchorEl]);
  const signout = () => {
    logout();
    setAnchorEl("");
  };

  const fullName = data?.first_name
    ? `${data?.first_name} ${data?.last_name}`
    : "Royal Badminton";
  const planLevel = data?.userMemberShipDetails?.membership.level ? data?.userMemberShipDetails?.membership.level : 0;
  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography fontSize={"inherit"} sx={{ textTransform: "capitalize" }}>
            {fullName}
          </Typography>
        }
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ p: 0 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {planLevel ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              badgeContent={
                <SmallAvatar
                  alt={`Plan_${planLevel}`}
                  src={planLevelIcons[planLevel]}
                />
              }
            >
              <MemberAvatar
                className={`level-${planLevel}`}
                {...stringAvatar(fullName, data?.profile_pic)}
                alt={fullName}
              />
            </Badge>
          ) : (
            <Avatar
              {...stringAvatar(fullName, data?.profile_pic)}
              alt={fullName}
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",
            borderRadius: "6px",
            mt: 1.5,
            "& .MuiAvatar-root": {
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid component={"li"} py={1.5} px={1} container>
          <Grid item>
            {planLevel ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                badgeContent={
                  <SmallAvatar
                    alt={`Plan_${planLevel}`}
                    src={planLevelIcons[planLevel]}
                  />
                }
              >
                <MemberAvatar
                  className={`level-${planLevel}`}
                  {...stringAvatar(fullName, data?.profile_pic)}
                  alt={fullName}
                />
              </Badge>
            ) : (
              <Avatar
                {...stringAvatar(fullName, data?.profile_pic)}
                alt={fullName}
              />
            )}
          </Grid>
          <Grid item>
            <Typography
              fontSize={15}
              sx={{ textTransform: "capitalize" }}
              fontWeight={600}
            >
              {data?.first_name || "User"}
            </Typography>
            <Typography
              component={Link}
              to="/profile"
              fontSize={"inherit"}
              color="text.light"
              fontWeight={500}
            >
              Go to Profile
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 1, mt: 0 }} />
        <MenuItem
          component={Link}
          to="/dashboard"
          onClick={handleClose}
          sx={{ p: "0.5rem" }}
        >
          <ListItemIcon>
            <DashboardCustomizeOutlinedIcon
              sx={{ fontSize: 19, fill: "#192335" }}
            />
          </ListItemIcon>
          Dashboard
        </MenuItem>
        <MenuItem
          component={Link}
          to="/my-bookings?tab=0"
          onClick={handleClose}
          sx={{ p: "0.5rem" }}
        >
          <ListItemIcon sx={{ fontSize: 18, color: "text.main" }}>
            <i className="feather-calendar" />
          </ListItemIcon>
          My Bookings
        </MenuItem>
        <MenuItem
          component={Link}
          to="/profile-settings"
          onClick={handleClose}
          sx={{ p: "0.5rem" }}
        >
          <ListItemIcon sx={{ fontSize: 18, color: "text.main" }}>
            <i className="feather-settings" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={signout}
          sx={{
            p: "0.5rem",
            color: "#C02B0A",
            "&:hover": { background: "#ff00001c", color: "#C02B0A" },
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" sx={{ fill: "#C02B0A" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export const Profiler = () => {
  const dispatch = useDispatch();
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((store) => store.auth);
  const { isOffline } = useSelector((state) => state.uiCtrl);
  const { data, isLoading, isFetching, error } = useUserProfileQuery(
    {},
    { skip: !isAuthenticated, refetchOnMountOrArgChange: false },
    isAuthenticated
  );
  if (error) {
    if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
      suiSnackbar("Internal server error!");
    } else if (error?.originalStatus === 503) {
      suiSnackbar("Service Unavailable!");
    } else if (error?.data) {
      if ([401, 500].includes(error?.data.statusCode)) {
        const payload = {
          token: null,
          isAuthenticated: false,
          loggedInTime: null,
          rememberMe: null,
        };
        localStorage.removeItem("auth_token", payload.token);
        localStorage.removeItem("loggedInTime", payload.loggedInTime);
        localStorage.removeItem("rememberMe", payload.rememberMe);
        navigate("/", { replace: true });
        dispatch(setCredentials(payload));
      }
      suiSnackbar(error?.data?.message);
    } else {
      suiSnackbar(
        isOffline
          ? "Check your Internet connection and try again!"
          : "Something went wrong!"
      );
    }
  }

  if (isAuthenticated) {
    return (
      <>
        {isLoading || isFetching ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
          <ProfileBox data={data?.data} />
        )}
      </>
    );
  } else {
    return (
      <LoginButton
        component={Link}
        to="/login"
        startIcon={<i className="feather-users" />}
        variant="contained"
      >
        Login / Register
      </LoginButton>
    );
  }
};
