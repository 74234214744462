import { Typography } from "@mui/material";

const Preloader = ({ custonClass = "" }) => {
  return (
    <div id="global-loader" className={`grid-center ${custonClass}`}>
      <span className="loader" />
      <Typography component={'p'} className="loading-text" color="#000" fontWeight={600} width={115} />
    </div>
  )
};

export default Preloader;