import colors from "theme/base/colors";
import pxToRem from "../../functions/pxToRem";
const { text, error } = colors;

export default {
  styleOverrides: {
    root: {
      display: "flex !important",
      padding: 0,
      borderRadius: 10,
      "& .MuiOutlinedInput-notchedOutline, &:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #FAFAFA",
        borderRadius: 10,
      },
      "&.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: `#FAFAFA !important`,
        },
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: `${text.main} !important`,
        },
      },
      "&.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: `${error.main} !important`,
        },
      }
    },

    input: {
      height: pxToRem(25),
      padding: pxToRem(17.5),
      borderRadius: 10,
      "&:disabled": {
        backgroundColor: "#FAFAFA",
        opacity: "0.7",
      },
    },

    inputSizeSmall: {
      height: pxToRem(18),
      padding: pxToRem(12),
      borderRadius: 7,
    },
    inputSizeLarge: {
      height: pxToRem(40),
    },
  },
};
