import { Box, Container, Grid, Typography } from "@mui/material";
import { UserNavBox } from "./style";
import { userNavData } from "common/websiteData";
import { NavLink } from "react-router-dom";

const UserNav = () => {
  return (
    <UserNavBox py={3} bgcolor={"#F5F5F5"}>
      <Container>
        <Grid container spacing={{ xs: 1.5, lg: 2 }} alignItems={'center'}>
          {userNavData?.map(({ id, href, label, icon }) => (
            <Grid key={id} item xs={6} sm={4} lg={2.4}>
              <Box className={"nav-link grid-center"} component={NavLink} to={href} py={{xs: 2, sm: 2.5, md: 3, xl: 4 }}>
                <img src={icon} alt={label} loading="lazy" />
                <Typography component={'span'} fontSize={'inherit'} fontWeight={'inherit'} color={'inherit'} lineHeight={'inherit'}>
                  {label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </UserNavBox>
  )
}

export default UserNav;