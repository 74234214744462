import InternetNotAvailableSnackbar from 'common/no-internet-alert';
import Preloader from 'common/preloader';
import { ResetScroll } from 'helper';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const RootLayoutCover = () => {
  return (
    <>
      <ResetScroll />
      <InternetNotAvailableSnackbar />
      <Suspense fallback={<Preloader />}>
        <Outlet />
      </Suspense>
    </>
  )
}

export default RootLayoutCover