import { createTheme } from "@mui/material";

import colors from "./base/colors";
import breakpoints from "./base/breakpoints";
import typography from "./base/typography";
import boxShadows from "./base/boxShadows";
import borders from "./base/borders";
import globals from "./base/globals";

import boxShadow from "./functions/boxShadow";
import hexToRgb from "./functions/hexToRgb";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";

import container from "./components/container";
import button from "./components/button";
import backDrop from "./components/backDrop";
import formLabel from "./components/form/formLabel";
import formControlLabel from "./components/form/formControlLabel";
import inputBase from "./components/form/inputBase";
import inputOutline from "./components/form/inputOutline";
import formHelperText from "./components/form/formHelperText";
import checkbox from "./components/form/checkbox";
import menu from "./components/menu";
import menuItem from "./components/menu/menuItem";
import divider from "./components/divider";
import dialog from "./components/dialog";
// import input from "./components/form/input";
// import list from "./components/list";
// import listItem from "./components/list/listItem";
// import listItemText from "./components/list/listItemText";
// import card from "./components/card";
// import cardMedia from "./components/card/cardMedia";
// import cardContent from "./components/card/cardContent";
// import iconButton from "./components/iconButton";
// import switchButton from "./components/form/switchButton";
// import tableContainer from "./components/table/tableContainer";
// import tableHead from "./components/table/tableHead";
// import tableCell from "./components/table/tableCell";
// import linearProgress from "./components/linearProgess";
// import breadcrumbs from "./components/breadcrumbs";
// import slider from "./components/slider";
// import avatar from "./components/avatar";
// import tooltip from "./components/tooltip";
// import appBar from "./components/appBar";
// import tabs from "./components/tabs";
// import tab from "./components/tabs/tab";
// import stepper from "./components/stepper";
// import step from "./components/stepper/step";
// import stepConnector from "./components/stepper/stepConnector";
// import stepLabel from "./components/stepper/stepLabel";
// import stepIcon from "./components/stepper/stepIcon";
// import select from "./components/form/select";
// import formControl from "./components/form/formControl";
// import radio from "./components/form/radio";
// import autocomplete from "./components/form/autocomplete";
// import popover from "./components/popover";
// import buttonBase from "./components/buttonBase";
// import icon from "./components/icon";
// import svgIcon from "./components/svgIcon";
// import link from "./components/link";
// import accordion from "./components/accordion";
// import rating from "./components/rating";

const theme = createTheme({
  breakpoints: { ...breakpoints },
  palette: { mode: "light", ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
  
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    MuiButton: { ...button },
    MuiBackdrop: { ...backDrop },
    MuiFormControlLabel: { ...formControlLabel },
    MuiFormLabel: { ...formLabel },
    MuiInputBase: { ...inputBase },
    MuiOutlinedInput: { ...inputOutline },
    MuiCheckbox: { ...checkbox },
    MuiFormHelperText: { ...formHelperText },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiDivider: { ...divider },
    MuiDialog: { ...dialog },
    // MuiList: { ...list },
    // MuiListItem: { ...listItem },
    // MuiListItemText: { ...listItemText },
    // MuiCard: { ...card },
    // MuiCardMedia: { ...cardMedia },
    // MuiCardContent: { ...cardContent },
    // MuiIconButton: { ...iconButton },
    // MuiSwitch: { ...switchButton },
    // MuiTableContainer: { ...tableContainer },
    // MuiTableHead: { ...tableHead },
    // MuiTableCell: { ...tableCell },
    // MuiLinearProgress: { ...linearProgress },
    // MuiBreadcrumbs: { ...breadcrumbs },
    // MuiSlider: { ...slider },
    // MuiAvatar: { ...avatar },
    // MuiTooltip: { ...tooltip },
    // MuiAppBar: { ...appBar },
    // MuiTabs: { ...tabs },
    // MuiTab: { ...tab },
    // MuiStepper: { ...stepper },
    // MuiStep: { ...step },
    // MuiStepConnector: { ...stepConnector },
    // MuiStepLabel: { ...stepLabel },
    // MuiStepIcon: { ...stepIcon },
    // MuiSelect: { ...select },
    // MuiFormControl: { ...formControl },
    // MuiRadio: { ...radio },
    // MuiAutocomplete: { ...autocomplete },
    // MuiInput: { ...input },
    // MuiFilledInput: { ...input },
    // MuiPopover: { ...popover },
    // MuiButtonBase: { ...buttonBase },
    // MuiIcon: { ...icon },
    // MuiSvgIcon: { ...svgIcon },
    // MuiLink: { ...link },
    // MuiAccordion: { ...accordion },
    // MuiRating: { ...rating },
  },
});

export default theme;