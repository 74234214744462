import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Preloader from "common/preloader";
import { ResetScroll } from "helper";
import Navbar from "common/navbar/Navbar";
import Footer from "common/footer/Footer";
import InternetNotAvailableSnackbar from "common/no-internet-alert";

const PagesRootLayout = () => {
  return (
    <>
      <ResetScroll />
      <InternetNotAvailableSnackbar />
      <Suspense fallback={<Preloader />}>
        <Navbar />
        <Outlet />
        <Footer />
      </Suspense>
    </>
  )
}

export default PagesRootLayout;