import { Box, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { CustomAppBar } from './style';
import { ElevationScroll, Logo, MobileNavToggler, NavBottomSpace, NavbarLink, Profiler, ScrollTop } from './navbarComponents';
import { Link, useLocation } from 'react-router-dom';
import { mainNavLinks } from 'common/websiteData';
import { BookCourtButtonUI } from 'components/button/ButtonUI';
const Navbar = (props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const viewLG = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <>
      <span id='back-to-top-anchor' />
      <ElevationScroll {...props}>
        <CustomAppBar>
          <Toolbar sx={{ minHeight: { xs: 80, sm: 56 }, justifyContent: "space-between" }}>
            {viewLG && <MobileNavToggler />}
            <Box component={Link} position={{ xs: 'absolute', sm: "relative" }} left={{ xs: '50%', sm: 10 }} top={{ xs: '50%', sm: 'unset' }}
              ml={{ xs: "auto", sm: 0 }} mr={{ xs: 'auto', lg: 0 }} sx={{ transform: { xs: 'translate(-50%, -50%)', sm: 'none' } }} to="/">
              <Logo />
            </Box>
            {!viewLG && (
              <Grid width="auto" container columnSpacing={2.5} justifyContent="center">
                {mainNavLinks?.map(({ id, href, label }) => (
                  <Grid item key={id}>
                    <NavbarLink href={href} label={label} />
                  </Grid>
                ))}
              </Grid>
            )}
            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} columnGap={1.5} width={{ xs: 'auto', sm: 148, xl: 162 }}>
              <Profiler />
            </Box>
          </Toolbar>
        </CustomAppBar>
      </ElevationScroll>
      <ScrollTop {...props} />
      {!['/book-court', '/private-team-lessons'].includes(pathname) && (
        <BookCourtButtonUI component={Link} to="/book-court" variant='contained' color='info'>
          Book a Court Now
        </BookCourtButtonUI>
      )}
      <NavBottomSpace />
    </>
  )
}

export default Navbar;