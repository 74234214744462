import colors from "../../base/colors";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

const { text } = colors;
const { size, fontWeightRegular } = typography;

export default {
  styleOverrides: {
    root: {
      minHeight: pxToRem(24),
      marginBottom: pxToRem(6),
      marginLeft: 0,
    },

    label: {
      display: "inline-block",
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      color: text.light,
      lineHeight: 1,
      transform: `translateY(${pxToRem(1)})`,
      marginLeft: pxToRem(4),
      "&.Mui-disabled": {
        color: text.light,
        opacity: "0.7",
      },
    },
  },
};
