
import { createSlice } from "@reduxjs/toolkit";
import { startCountdown } from "helper";
const [min, sec] = startCountdown();
const initialState = {
  min: min,
  sec: sec,
};

export const otpSlice = createSlice({
  name: "otpSlice",
  initialState,
  reducers: {
    setOTPTimer: (state, action) => {
      state.min = action.payload.min;
      state.sec = action.payload.sec;
    },
    setDefault: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem('sendOTPtime');
    }
  },
});

export const { setOtpCredentials, setOTPTimer, setVerified, setPush, setDefault } = otpSlice.actions;

export default otpSlice.reducer;
