import colors from "theme/base/colors";
import rgba from "theme/functions/rgba";
import pxToRem from "theme/functions/pxToRem";

const { transparent } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      backgroundImage: `linear-gradient(to right, ${rgba("#EAEDF0", 0)}, ${rgba(
        "#000",
        0.7
      )}, ${rgba("#EAEDF0", 0)}) !important`,
      height: pxToRem(1),
      margin: `${pxToRem(16)} 0`,
      borderBottom: "none",
      opacity: 0.25,
    },

    vertical: {
      backgroundColor: transparent.main,
      backgroundImage: `linear-gradient(to bottom, ${rgba("#EAEDF0", 0)}, ${rgba(
        "#000",
        0.7
      )}, ${rgba("#EAEDF0", 0)}) !important`,
      width: pxToRem(1),
      height: "100%",
      margin: `0 ${pxToRem(16)}`,
      borderRight: "none",
    },

    light: {
      backgroundColor: transparent.main,
      backgroundImage: `linear-gradient(to right, ${rgba("#EAEDF0", 0)}, ${rgba(
        "#000",
        0.7
      )}, ${rgba("#EAEDF0", 0)}) !important`,

      "&.MuiDivider-vertical": {
        backgroundImage: `linear-gradient(to bottom, ${rgba("#EAEDF0", 0)}, ${rgba(
          "#EAEDF0",
          0.5
        )}, ${rgba("#EAEDF0", 0)}) !important`,
      },
    },
  },
};
