import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";

const BreadcrumbUI = ({ currentPage, breadcrumbArrey = [] }) => {
  return (
    <>
      <Typography component="h1" mb={1.875} fontWeight={700} fontSize={{ xs: 32, lg: 36 }}>{currentPage}</Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ fill: "#fff" }} />} aria-label="breadcrumb">
        <Typography component={Link} fontSize={16} color="light.main" lineHeight={1.2}
          sx={{ transition: "all 0.5s ease-in-out 0s", "&:hover": { color: "#AAF40C" } }} to="/">
          Home
        </Typography>
        {breadcrumbArrey?.map(({ id, label, href }) => (
          <Typography key={`breadcrumb_${id}`} component={Link} fontSize={16} color="light.main" lineHeight={1.2}
            sx={{ transition: "all 0.5s ease-in-out 0s", "&:hover": { color: "#AAF40C" } }} to={href}>
            {label}
          </Typography>
        ))}
        <Typography fontSize={16} color="light.main">{currentPage}</Typography>
      </Breadcrumbs>
    </>
  )
}

export default BreadcrumbUI;