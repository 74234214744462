
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("auth_token"),
  token: localStorage.getItem("auth_token"),
  loggedInTime: localStorage.getItem("loggedInTime"),
  rememberMe: localStorage.getItem("rememberMe"),
  searchParams: {},
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.loggedInTime = action.payload.loggedInTime;
      state.rememberMe = action.payload.rememberMe;
    },
    Logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('auth_token');
      localStorage.removeItem('loggedInTime');
      localStorage.removeItem('rememberMe');
    },
    getSorting: (state, action) => {
      const data = action.payload;
      state.searchParams = { ...state.searchParams, ordering: `${data.order}${data.orderBy}` };
    },
    setSearchParams: (state, action) => {
      const data = action.payload;
      state.searchParams = data;
    },
  },
});

export const { setCredentials, getSorting, Logout, setSearchParams } = authSlice.actions;

export default authSlice.reducer;
