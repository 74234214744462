import typography from "theme/base/typography";
import pxToRem from "theme/functions/pxToRem";
import { LG } from "../container";
import colors from "theme/base/colors";


const { transparent, info, secondary, grey, primary, text: textColor, error } = colors;
const { size } = typography;

const text = {
  base: {
    backgroundColor: transparent.main,
    height: 'max-content',
    color: info.main,
    boxShadow: 'none',
    padding: `${pxToRem(10)} ${pxToRem(15)}`,

    '&:hover': {
      backgroundColor: transparent.main,
      boxShadow: 'none',
      color: info.focus,
    },

    '&:focus': {
      boxShadow: 'none',
      color: info.focus,
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
      boxShadow: 'none',
    },

    '&:disabled': {
      color: grey[600],
      opacity: 0.85,
      boxShadow: 'none',
    },

    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(15)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(12)}`,
    fontSize: size.xs,

    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(19)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(45),
    padding: `${pxToRem(12)} ${pxToRem(20)}`,
    fontSize: 16,
    [LG]: {
      fontSize: size.lg,
    },
    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(18)} !important`,
    },
  },

  primary: {
    color: primary.main,
    backgroundColor: transparent.main,

    '&:hover, &:focus': {
      color: primary.focus,
      backgroundColor: transparent.main,
    },
  },

  secondary: {
    color: secondary.focus,
    backgroundColor: transparent.main,

    '&:hover, &:focus': {
      color: secondary.focus,
      backgroundColor: transparent.main,
    },
  },

  info: {
    color: info.main,
    backgroundColor: transparent.main,

    '&:hover, &:focus': {
      color: info.focus,
      backgroundColor: transparent.main,
    },
  },

  error: {
    color: error.main,
    backgroundColor: transparent.main,

    '&:hover, &:focus': {
      color: error.focus,
      backgroundColor: transparent.main,
    },
  },

  dark: {
    color: textColor.main,
    backgroundColor: transparent.main,

    '&:hover, &:focus': {
      color: primary.focus,
      backgroundColor: transparent.main,
    },
  },
};
export default text;
