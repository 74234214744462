import boxShadow from "theme/functions/boxShadow";
import colors from "../../base/colors";
import typography from "../../base/typography";

const { text, error } = colors;
const { size, fontWeightRegular } = typography;

export default {
  defaultProps: {
    inputProps: {
      autoComplete: "off",
    }
  },
  styleOverrides: {
    root: {
      display: "grid",
      placeItems: "center",
      width: "100%",
      height: "auto",
      padding: 0,
      fontSize: size.regular,
      fontWeight: fontWeightRegular,
      lineHeight: 1.4,
      color: text.main,
      backgroundColor: "#FAFAFA",
      backgroundClip: "padding-box",
      border: "none",
      appearance: "none",
      borderRadius: 10,
      transition: "box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease",
      "&.Mui-focused": {
        backgroundColor: "#fff",
        "&> .MuiInputBase-input": {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `${boxShadow("#fff", 1, [0, 0], [0, 30], "inset")} !important`,
          },
        }
      },
      "&.Mui-error": {
        backgroundColor: `${error.main}08`,
        "&> .MuiInputBase-input": {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `inset 0rem 0rem 0rem 1.875rem #FDF8F7 !important`,
          },
        }
      },
    },

    input: {
      width: "100% !important",
      borderRadius: 10,
      "&::-webkit-input-placeholder": {
        color: "#6B7385 !important",
        opacity: 1,
      },
      "&:-webkit-autofill": {
        WebkitBoxShadow: `${boxShadow("#FAFAFA", 1, [0, 0], [0, 30], "inset")} !important`,
      },
    },
  },
};
