import boxShadow from "theme/functions/boxShadow";
import colors from "./colors";

const { black, white, info, inputColors, tabs } = colors;

export default {
  xs: boxShadow(black.main, 0.15, [0, 2], [9, -5]),
  sm: boxShadow(black.main, 0.12, [0, 5], [10, 0]),
  regular: `${boxShadow(black.light, 0.12, [0, 4], [6, -1])}, ${boxShadow(
    black.light,
    0.07,
    [0, 2],
    [4, -1]
  )}`,
  lg: `${boxShadow(black.light, 0.15, [0, 8], [26, -4])}, ${boxShadow(
    black.light,
    0.06,
    [0, 8],
    [9, -5],
  )}`,
  xl: boxShadow(black.light, 0.25, [0, 23], [45, -11]),
  xxl: boxShadow(black.main, 0.05, [0, 20], [27, 0]),
  inset: boxShadow(black.main, 0.075, [0, 1], [2, 0], "inset"),
  navbarBoxShadow: `${boxShadow(white.main, 0.9, [0, 0], [0.5, 0.5], "inset")}, ${boxShadow(
    black.main,
    0.05,
    [0, 16],
    [20, 0]
  )}`,
  buttonBoxShadow: {
    main: `${boxShadow(black.main, 0.11, [0, 4], [7, -1])}, ${boxShadow(
      black.main,
      0.07,
      [0, 2],
      [4, -1]
    )}`,
    stateOf: `${boxShadow(black.main, 0.09, [0, 3], [5, -1])}, ${boxShadow(
      black.main,
      0.07,
      [0, 2],
      [5, -1]
    )}`,
    stateOfNotHover: boxShadow(info.main, 0.5, [0, 0], [0, 3.2]),
  },
  inputBoxShadow: {
    focus: boxShadow(inputColors.boxShadow, 1, [0, 0], [0, 2]),
    error: boxShadow(inputColors.error, 0.6, [0, 0], [0, 2]),
    success: boxShadow(inputColors.success, 0.6, [0, 0], [0, 2]),
  },
  sliderBoxShadow: {
    thumb: boxShadow(black.main, 0.2, [0, 1], [13, 0]),
  },
  tabsBoxShadow: {
    indicator: boxShadow(tabs.indicator.boxShadow, 1, [0, 1], [5, 1]),
  },
};
