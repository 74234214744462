import { baseAPIService } from ".";

const UserApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    contactUs: build.mutation({
      query: (data) => {
        return {
          url: "user/contactUsQuery",
          method: "POST",
          body: data,
        };
      },
    }),
    userProfile: build.query({
      query: () => {
        return {
          url: "user/profile",
        };
      },
      keepUnusedDataFor: 1200,
      providesTags: ['GET_PROFILE'],
    }),
    userProfileUpdate: build.mutation({
      query: (data) => {
        return {
          url: "user/updateProfile",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GET_PROFILE"],
    }),
    userChangePassword: build.mutation({
      query: (data) => {
        return {
          url: "user/changePassword",
          method: "PATCH",
          body: data,
        };
      },
    }),
    userMyBookingCourt: build.query({
      query: (params = {}) => {
        return {
          url: "user/myBooking",
          params: { ...params, limit: 5 }
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['GET_BOOKING'],
    }),
    userDashboard: build.query({
      query: (params = {}) => {
        return {
          url: "user/dashboard",
          params: { ...params, page: 1, limit: 6 }
        };
      },
      keepUnusedDataFor: 120,
      providesTags: ['GET_BOOKING'],
    }),
  }),
  overrideExisting: true,
});
export const {
  useContactUsMutation,
  useUserProfileQuery,
  useUserProfileUpdateMutation,
  useUserChangePasswordMutation,
  useUserMyBookingCourtQuery,
  useUserDashboardQuery,
} = UserApi;
