import { useEffect, useState } from "react";

const useGetScroller = () => {
  const [scrollOffset, setScrollOffset] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const height = document.documentElement.scrollHeight - window.innerHeight;
    const percentage = (scrollY / height) * 100;
    setScrollOffset(percentage);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [scrollOffset]
}

export default useGetScroller;