
import { Button, styled } from "@mui/material";

export const BookCourtButtonUI = styled(Button)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    position: 'fixed',
    top: '40%',
    right: -50,
    zIndex: 99,
    transform: 'rotateZ(-90deg)',
    [breakpoints.down('xl')]: {
      right: -45,
    }
  }
})