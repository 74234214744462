import BreadcrumbUI from 'components/breadcrumb';
import { BreadcrumbHeaderStyled } from './style';
import { Container } from '@mui/material';

const BreadcrumbHeader = ({ customClass = '', ...rest }) => {
  return (
    <BreadcrumbHeaderStyled className={customClass}>
      <span className="primary-right-round" />
      <Container className='breadcrumb-box'>
        <BreadcrumbUI {...rest} />
      </Container>
    </BreadcrumbHeaderStyled>
  )
}

export default BreadcrumbHeader;