import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authSlice from './features/authSlice';
import otpSlice from './features/otpSlice';
import uiCtrlSlice from './features/uiCtrlSlice';
import { baseAPIService } from './features/apis';

const store = configureStore({
  reducer: {
    auth: authSlice,
    uiCtrl: uiCtrlSlice,
    getOtp: otpSlice,
    [baseAPIService.reducerPath]: baseAPIService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(baseAPIService.middleware),
  // devTools: true,
});

setupListeners(store.dispatch);

export default store;
