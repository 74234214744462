
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOffline: Boolean(!window.navigator.onLine),
};

export const uiCtrlSlice = createSlice({
  name: "uiCtrlSlice",
  initialState,
  reducers: {
    setIsOffline: (state, action) => {
      state.isOffline = action.payload;
    }
  },
});

export const { setIsOffline } = uiCtrlSlice.actions;

export default uiCtrlSlice.reducer;
