import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    useLogin: build.mutation({
      query: (data) => {
        return {
          url: "user/login",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["COMMON_GET", "GET_PROFILE", "GET_VENUE", 'GET_BOOKING', 'GET_COURT', 'GET_COURT_SLOT'],
    }),
    userLogout: build.mutation({
      query: () => {
        return {
          url: "user/logout",
          method: "POST",
        };
      },
    }),
    userSignup: build.mutation({
      query: (data) => {
        return {
          url: "user/register",
          method: "POST",
          body: data,
        };
      },
    }),
    userVerifyOtp: build.mutation({
      query: ({ data, token }) => {
        return {
          url: "user/verifyOtp",
          method: "POST",
          headers: {
            authtoken: token,
          },
          body: data,
        };
      },
      invalidatesTags: ["GET_PROFILE"],
    }),
    forgotPassword: build.mutation({
      query: (data) => {
        return {
          url: "user/forgotPassword",
          method: "POST",
          body: data,
        };
      },
    }),
    resetPassword: build.mutation({
      query: ({ data, token }) => {
        return {
          url: "user/resetPassword",
          method: "PATCH",
          headers: {
            authtoken: token,
          },
          body: data,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useUseLoginMutation,
  useUserLogoutMutation,
  useUserSignupMutation,
  useUserVerifyOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = AuthApi;
